.MuiDialogContent-root {
  flex: 1 1 auto;
  padding: 0 !important;
  overflow-y: visible !important;
  -webkit-overflow-scrolling: touch;
}
.stepper {
  margin-bottom: 20px !important;
}

.stepper span,
.stepper a {
  line-height: 31px !important;
}

.collapsing {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
  transition: height 0.2s ease;
}


input[type='range'] {
  width: 100%;
  margin: 13.8px 0;
  background-color: transparent;
  -webkit-appearance: none;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  background: #3071a9;
  border: 0.2px solid #010101;
  border-radius: 1.3px;
  width: 100%;
  height: 8.4px;
  cursor: pointer;
}
input[type='range']::-webkit-slider-thumb {
  margin-top: -14px;
  width: 16px;
  height: 36px;
  background: #e0927f !important;
  border: 1px solid #000000;
  border-radius: 3px;
  cursor: pointer;
  -webkit-appearance: none;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: #367ebd;
}
input[type='range']::-moz-range-track {
  background: #3071a9;
  border: 0.2px solid #010101;
  border-radius: 1.3px;
  width: 100%;
  height: 8.4px;
  cursor: pointer;
}
input[type='range']::-moz-range-thumb {
  width: 16px;
  height: 36px;
  background: #e0927f !important;
  border: 1px solid #000000;
  border-radius: 3px;
  cursor: pointer;
}
input[type='range']::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 14.8px 0;
  color: transparent;
  width: 100%;
  height: 8.4px;
  cursor: pointer;
}
input[type='range']::-ms-fill-lower {
  background: #2a6495;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
}
input[type='range']::-ms-fill-upper {
  background: #3071a9;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
}
input[type='range']::-ms-thumb {
  width: 16px;
  height: 36px;
  background: #89ffff;
  border: 1px solid #000000;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}
input[type='range']:focus::-ms-fill-lower {
  background: #3071a9;
}
input[type='range']:focus::-ms-fill-upper {
  background: #367ebd;
}
/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align: auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type='range'] {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}
input[type='range'].range-slider {
  height: 10px !important;
}

.range-slider__wrap {
  position: relative;
  display: block;
  /* height: calc(1.5em + 0.75rem + 2px); */
  height: 19px;
}

.range-slider__wrap .range-slider__tooltip.range-slider__tooltip--bottom {
  top: 24px !important;
  bottom: auto;
}

.range-slider__wrap .range-slider__tooltip.range-slider__tooltip--top {
  bottom: 46px !important;
  top: auto;
}

.slider-container {
  height: 17px;
}

.slider-container .slider {
  width: 100%;
  margin-right: 2px;
}

.slider-range-label-left {
  width: 100%;
  margin-top: 11px;
  font-size: 12px;
  height: 5px;

  :nth-child(1) {
    margin-left: 2px;
  }

  .best-health,
  worse-health {
    float: right;
  }
}

.slider-range-label-right {
  width: 100%;
  font-size: 12px;

  .best-health,
  worse-health {
    float: right;
  }
}

// **************  END - Custom Range Field  **************

.field-radio-group {
  padding-left: 15px;
}
.field > .control-label {
  font-size: 16px;
  font-weight: bold;
}

// **************  START - PLAY  **************
.play-container {
  //display: inline-flex;
  //float: right;
}

.play-description {
  max-width: 300px;
}

.play {
  width: 136px;
  margin-top: -10px;
}

.play-desc {
  width: 300px;
  span {
    font-size: 1rem;
  }
}

.top {
  background-color: #ffffff;
  width: 100%;
  height: 57px;
  position: relative;
  border-top: 3px solid #a6bfbe7a;
  border-left: 3px solid #a6bfbe7a;
  border-right: 3px solid #a6bfbe7a;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 3px;
}

.top:after {
  content: ' ';
  position: absolute;
  bottom: -20px;
  left: 50%;
  margin-left: -20px;
  border-style: solid;
  border-width: 20px 20px 0 20px;
  border-color: #ffffff transparent transparent transparent;
}
.bottom {
  background-color: #a6bfbe;
  width: 100%;
  height: 90px;
  border: 1px solid #a6bfbe;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-top: 21px;
}

.bottom span {
  color: white;
  font-size: 15px;
}
.bottom button {
  margin: 3px 0 5px 0;
  font-size: 13px;
}


/////////////////// LOGIN ////////////////////////
.login100-form-title {
  font-size: 15px;
  color: #e0927e;
  text-align: center;
  display: block;
  font-weight: bold;
}


.login100-form {
  padding: 50px 20px 50px 20px;
  width: 300px;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #ffffff;
}

.wrap-login100 {
  /* width: 960px; */
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  //box-shadow: 3px 1px 5px 0px #a6bfbd;
}

.login100-pic {
  //background-color: #f7e2c94a;
}

@media (max-width: 992px) {
  .wrap-login100 {
    padding: 37px 25px 20px 25px;
  }
  .login100-pic {
    width: 496px;
  }
}

.login100-pic img {
  width: 816px;
  margin-top: 9px;
}

.input100 {
  font-size: 15px;
  color: #666666;
  border: 1px solid darkgrey;
  display: block;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  padding: 0 30px 0 68px;
  margin: 10px 0;
}

.login100-form-btn {
  font-size: 15px;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;
  background-color: #e0927e;
  width: 15%;
  height: 35px;
  border-radius: 25px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  border: 1px solid #e0927e;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn:hover {
  background: #333333;
}

//////// Another style of Login////////

.landing-page-logo {
  height: auto !important;
  width: 86px !important;
  margin-left: 90px;
}
.heading {
  color: #e0927e;
  font-size: 24px !important;
}

.login-modal {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}

.modal-dialog {
  position: absolute !important;
  top: 51% !important;
  left: 50% !important;
  transform: translate(-50%, -51%) !important;
}

//////// Another style of Login////////
.login-btn {
  padding: 7px 10px !important;
  background-color: #e0927e !important;
  color: white;
  font-size: 16px;
  border: none;
  margin-top: 10px;
  margin-left: 40px;
}

@media screen and (min-width: 360px) and (max-width: 768px){
  .landing-img {
    min-width: 410px;
  }
  .login-btn {
    width: 280px;
    margin-left: 35px;
    margin-top: 15px;
    padding: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 920px){
  .landing-img {
    min-width: 410px;
  }
  .login-btn {
    width: 310px;
    margin-left: 45px;
    margin-top: 15px;
    padding: 10px;
  }
}


@media screen and (min-width: 920px) and (max-width: 1920px){
  .landing-img {
    max-width: 550px !important;
  }
  .login-btn {
    width: 370px;
    margin-left: 45px;
    margin-top: 15px;
    padding: 10px !important;
  }
}

.landing-container {
  width: 100%;
  height: 100%;
  padding-left: 0 !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #e0927e !important;
  background-color: #e0927e !important;
}


.btn4:hover {
  color: white;
  background-color: #e0927e !important;
}

//Questionnaires Modal
#questionnaire-modal #root {
  height: 100% !important;
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 7.5rem);
}

input[type='radio'],
input[type='checkbox'] {
  margin-right: 5px;
}

.play-field .form-control {
  height: 40px !important;
}
#questionnaire-modal .form-group.field.field-number {
  margin-bottom: 4rem !important;
  margin-top: 2rem !important;
}
